import React, {useRef, useState, useImperativeHandle, forwardRef} from 'react';
import {Select} from 'antd';
import * as _ from 'lodash';

export default forwardRef((props, ref) => {
  const inputRef = useRef();
  const {colDef, node, stopEditing} = props;
  const [value, setValue] = useState(_.get(colDef, 'cellEditorParams.mode', null) === 'multiple' ? props.value ?? [] : props.value ?? '');

  function inputHandler(value) {
    setValue(value);
  }

  function blurHandler() {
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => value,
      afterGuiAttached: () => {
        setValue(props.value);
        inputRef.current.focus();
      }
    };
  });

  const opts = _.get(colDef, 'cellEditorParams.opts', {});
  const extra = _.get(colDef, 'cellEditorParams.extra', {});
  const maxSelects = _.get(colDef, 'cellEditorParams.maxSelects', null);
  const selectList = _.get(colDef, 'cellEditorParams.selectList', []);

  console.log(value);

  return (
    <Select style={{ width: '100%' }} ref={inputRef} {...opts} onChange={inputHandler} defaultValue={opts.mode === 'multiple' && !value ? [] : value} value={value} {...extra} onBlur={blurHandler}>
      {selectList.map(item => <Select.Option key={item.id} value={item.id} disabled={_.isArray(value) && maxSelects !== null && value.length === maxSelects && !value.includes(item.id)}>{item.label}</Select.Option>)}
    </Select>
  )
});
