import React from 'react'
import * as _ from 'lodash'
import LMTable from 'Components/LMTable'
import { Button, Checkbox, Col, Menu, Row, Dropdown, Tooltip, Icon } from 'antd'
import { ActionBar, StyledButton } from 'Components/OpecomReferencesTable/styled'
import { Wrapper } from 'Common/styled'
import api from 'Common/api'
import PropTypes from 'prop-types'
import AppAccess from 'Common/appAccess'
import {
  hasChanged,
  handleServerSuccess,
  handleServerError,
  feedbackNotification, num, transformFilters, isEmpty
} from 'Common/utils'
import { ROLE_AZ_COMM, ROLE_CP, ROLE_CS, ROLE_CS_WEB, ROLE_GUEST, ROLE_MERCH } from 'Common/constants/global'
import { REFERENCE_STATE } from 'Common/globals'
import AgTable from 'Components/AgTable/AgTable'
import { colors } from 'Common/constants'
import {
  SPT_MUST_NULL, PREV_TOT_MUST_NULL
} from 'Common/constants/product-warnings'
import OpecomQtaImpegnataModalContent from 'Components/OpecomReferencesTable/OpecomQtaImpegnataModalContent'
import * as moment from 'moment';
import {
  PREV_VENDUTO_TOT_OBL, DATA_COMPLETAMENTO_GTE_DATA_INIZIO,
  DATA_COMPLETAMENTO_NON_VALIDA, DATA_FINE_EVENTO_NON_VALIDA,
  DATA_INIZIO_EVENTO_NON_VALIDA, STOCK_LT_STOCK_SEDE , PL_PREV_TOT_NULL_OR_ZERO,
  PL_PREV_TOT_PERC_NULL_OR_ZERO, STOCK_SEDE_OBL
} from 'Common/constants/product-errors'
import OpecomIncompleteReferencesModal from './OpecomIncompleteReferencesModal';
import OpecomDeadLineModal from './OpecomDeadLineModal';
import Loader from 'Components/Loader'
/**
 * Component description
 */
class OpecomReferencesTable extends React.Component {
  state = {
    visibleColumns: [],
    backupData: [],
    items: [],
    totalItems: 0,
    page: 1,
    filters: {},
    sorter: {},
    selectedKeys: [],
    deselectedKeys: [],
    selectedAll: false,
    columnsMenuVisible: false,
    tableLoading: false,
    changes: [],
    refNotUpdated: [],
    loading: true,
    canSave: true,
    newRefsCount: 0,
    chosenCluster: null,
    fullscreen: false,
    recordChanges: {},
    selectedNodes: [],
    showIncompleteReferences: true,
    references: [],
    showOpecomDeadLineModal: true
  }

  constructor(props, context) {
    super(props, context);
    this.agTable = React.createRef();
  }

  /**
   * When component mounts, fill visible columns in the state
   */
  componentDidMount() {
    const { opecom } = this.props;
  }

  async getIncompleteReferences() {
    const {opecom} = this.props;
    api.opecom.getAllRefsIncomplete(opecom.id).then(res => this.setState({references: res.data}))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {opecom} = this.props;
    if (hasChanged(['page'], prevState, this.state)) {
      this.fetchData()
    } else if (
      hasChanged(['filters', 'sorter'], prevState, this.state)
    ) {
      this.setState({ page: 1 }, this.fetchData)
    }
  }

  showOpecomDeadLineModal = () => {
    const {user, opecom} = this.props;
    const {showOpecomDeadLineModal} = this.state;
    let days = 41;

    if (opecom.code === 1073 && opecom.idStore) {
      days = 35;
    }

    const endShowModalDate = moment(opecom.startDate).subtract( days+1, 'd');
    const startShowModalDate = moment(opecom.startDate).subtract( days + 5, 'd');
    const today = moment();

   if ((((user.role === 'CS')) && ((today <= endShowModalDate) && (today >= startShowModalDate )))) {
      return (
        <OpecomDeadLineModal
          visible={showOpecomDeadLineModal}
          title={opecom.description}
          endDate={endShowModalDate}
          onCancel={() => {
            this.setState({ showOpecomDeadLineModal: false })
          }}
        />
      )
    }
  }

  showIncompleteReferencesModal = () => {
    const {user, opecom} = this.props;
    const {showIncompleteReferences, references} = this.state;
    const startShowModalDate = moment(opecom.startDate).subtract(60, 'd');
    const endShowModalDate = moment(opecom.startDate).subtract(51, 'd');
    const today = moment();

   if (((user.role === 'CP') || (user.role === 'AZ_COMM')) && ((today <= endShowModalDate) && (today >= startShowModalDate ))) {
      return (
        <OpecomIncompleteReferencesModal
          visible={showIncompleteReferences}
          items={references}
          onCancel={() => {
            this.setState({ showIncompleteReferences: false })
          }}
        />
      )
    }
  }
  /**
   * Get table columns
   * @returns []
   */
  getColumns = opecomParam => {
    const { user } = this.props;
    let opecom = this.props.opecom;

    if (opecomParam) {
      opecom = opecomParam;
    }

    let columns = [
      {
        title: 'IMMAGINE',
        dataIndex: 'refFoto',
        align: 'center',
        width: 100,
        onHeaderCell: column => ({style: {minWidth: '100px'}}),
        onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
        renderer: 'productImage'
      },
      {
        title: 'REPARTO',
        dataIndex: 'refNomeReparto',
        width: 200,
      },
      {
        title: 'SOTTOREPARTO',
        dataIndex: 'refNomeSottoreparto',
        sortable: true,
        width: 200,
      },
      {
        title: 'TIPO',
        dataIndex: 'refNomeTipo',
        searchable: true,
        sortable: true,
        width: 200,
      },
      {
        title: 'SOTTOTIPO',
        dataIndex: 'refNomeSottotipo',
        sortable: true,
        searchable: true,
        width: 200,
      },
      {
        title: 'REF LM',
        dataIndex: 'idReferenza',
        key: 'idReferenza',
        searchable: true,
        sortable: true,
        width: 150,
      },

      {
        title: 'DESCRIZIONE',
        dataIndex: 'refDescrizione',
        searchable: true,
        sortable: true,
        width: 250,
      },
      {
        title: 'GAMMA',
        dataIndex: 'refGamma',
        filtrable: true,
        filters: [
          { value: 'A', text: 'A' },
          { value: 'B', text: 'B' },
          { value: 'C', text: 'C' },
          { value: 'D', text: 'D' },
          { value: 'L', text: 'L' },
          { value: 'P', text: 'P' },
          { value: 'R', text: 'R' },
          { value: 'S', text: 'S' },
        ],
        width: 100,
      },
      {
        title: 'STATO',
        dataIndex: 'refStato',
        filtrable: true,
        filters: [
            {text: 'Attivo commercio', value: 'AC'},
            {text: 'Attivo servizio interno', value: 'ASI'},
            {text: 'In soppressione', value: 'ESUP'},
            {text: 'Inizializzato', value: 'INI'},
            {text: 'Pre-inizializzato', value: 'PRE'},
            {text: 'Soppresso', value: 'SUP'},
            {text: 'Soppressione programmata', value: 'SUPP'}
        ],
        width: 200,
        render: (value, record) => {
            return REFERENCE_STATE[value].value;
        }
      },
      {
        title: (
          <span className="special-table-head-title">
            <i>TIPO PRODOTTO</i> IN PROMO
          </span>
        ),
        cleanTitle: 'TIPO PRODOTTO IN PROMO',
        dataIndex: 'tipoProdotto',
        filtrable: true,
        filters: [
          { value: 'STAR', text: 'STAR' },
          { value: 'NOSTAR', text: 'NOSTAR' }
        ],
        editable:
          !opecom ||
          (user.role === ROLE_AZ_COMM &&
            opecom.idStore === null &&
            ['CR', 'R1'].indexOf(opecom.statoOpecom) > -1),
        editableSelect: true,
        editableSelectList: [
          { id: 'STAR', label: 'STAR' },
          { id: 'NOSTAR', label: 'NOSTAR' },
        ],
        validation: true,
        validationOpts: {
          needsConfirmation: true,
          skipConfirmation: (value, record, column) =>
            (!record.prevVtot &&
              record.prevVtot !== 0.0 &&
              !record.prevVtotPerc &&
              record.prevVtotPerc !== 0.0) ||
            value === 'STAR' ||
            (value === 'NOSTAR' &&
              (['INI', 'PRE'].includes(record.stato) ||
                record.refGamma === 'P')),
          confirmationText:
            'Il valore di prev venduto sarà resettato, sei sicuro di voler procedere?',
        },
        width: 130,
      },
      {
        title: 'MECCANICA COMMERCIALE',
        dataIndex: 'meccom',
        width: 100,
        valueFormatter: params => {
          switch (params.value) {
              case '3':
                  return 'ESSENTIAL';
              case '4':
                  return 'OPTIONAL';
              case '34':
                  return 'ESSENTIAL';
              default:
                  return '';
          }
        }
      },
      {
        title: 'REF IMPORT',
        dataIndex: 'refImport',
        contentType: 'boolean',
        width: 100,
      },
      {
        title: 'VOLANTINO',
        dataIndex: 'volantino',
        contentType: 'bool',
        editable: (value, record) =>
          user.role === ROLE_AZ_COMM &&
          opecom.idStore === null &&
          ['CR', 'R1'].includes(opecom.statoOpecom) &&
          record.refImport !== true &&
          record.meccom === '3',
        filtrable: true,
        filters: [
          { value: true, text: 'Si' },
          { value: false, text: 'No' }
        ],
        filterMultiple: false
      },
      {
        title: (
          <span className="special-table-head-title">
            <i>VENDUTO</i> ANNO PRECEDENTE
          </span>
        ),
        cleanTitle: 'VENDUTO ANNO PRECEDENTE',
        dataIndex: 'vendutoAnnoPrecedente',
        contentType: 'decimal',
        precision: 1,
        customVisibility:
          [ROLE_CP, ROLE_GUEST].includes(user.role) && opecom && opecom.idStore === null,
        width: 150,
      },
      {
        title: (
          <span className="special-table-head-title">
            <i>PREV VENDUTO</i> TOTALE (QTA)
          </span>
        ),
        cleanTitle: 'PREV VENDUTO TOTALE (QTA)',
        dataIndex: 'prevVtot',
        key: 'prevVtot',
        contentType: 'decimal',
        precision: 1,
        maxValue: (record, newValue, oldValue) => {
          const prevVtotForecast = Number(record.prevVtotForecast);
          const percentageLimit = record.tipoProdotto === 'STAR' ? 1 : 0.5;
          const isForecasted = record.forecasted === true;

          if (!isForecasted) {
            return;
          }

          if (isNaN(prevVtotForecast)) {
            return;
          }

          return prevVtotForecast * (1 + percentageLimit);
        },
        customVisibility:
          [ROLE_CP, ROLE_GUEST, ROLE_AZ_COMM].includes(user.role) && opecom && opecom.idStore === null,
        editable: (value, record) => {

          if (record.lottoRef !== 'NO') {
            return false;
          }

          if (!['CR', 'R1'].includes(opecom.statoOpecom)) {
            return false;
          }

          if (record.odrCentralizzati === true) {
            return false;
          }

          if (opecom.idStore !== null) {
            return false;
          }

          if (user.role === ROLE_CP && record.meccom === '4' && record.tipoProdotto !== 'NOSTAR') {
            return true;
          }

          if (user.role === ROLE_CP && record.meccom !== '4') {
            return true;
          }

          return false;
        }


          /* &&
          (record.tipoProdotto === 'STAR' ||
            (record.tipoProdotto === 'NOSTAR' &&
              (['INI', 'PRE'].includes(record.stato) ||
                record.refGamma === 'P'))) */,
        batchEditable: true,
        disabled: record => record.prevVtotPerc || record.prevVtotPerc === 0.0,
        width: 130,
      },
      {
        title: (
          <span className="special-table-head-title">
            <i>FORECAST</i> RELEX (QTA)
          </span>
        ),
        cleanTitle: 'FORECAST RELEX (QTA)',
        dataIndex: 'prevVtotForecast',
        key: 'prevVtotForecast',
        contentType: 'decimal',
        precision: 1,
        customVisibility:
          [ROLE_CP, ROLE_GUEST, ROLE_AZ_COMM].includes(user.role) && opecom && opecom.idStore === null,
        width: 130,
      },
      {
        title: (
          <span className="special-table-head-title">
            <i>PREV VENDUTO</i> <small>NEGOZIO RELEX (QTA)</small>
          </span>
        ),
        cleanTitle: 'PREV VENDUTO NEGOZIO RELEX',
        dataIndex: 'prevVtotRelex',
        contentType: 'decimal',
        precision: 1,
        customVisibility: [ROLE_CS, ROLE_CS_WEB, ROLE_GUEST].includes(user.role) && opecom && opecom.idStore !== null,
        width: 130,
      },
      {
        title: (
          <>
            <span className="special-table-head-title">
              <i>PREV VENDUTO</i> <small>NEGOZIO (QTA)</small>
            </span>
            <Tooltip title={<>
              Valgono le seguenti regole:
            <ul>
                <li>prodotti STAR: modificabile solo al rialzo rispetto al valore di Relex</li>
                <li>prodotti NOSTAR in gamma P: modificabile al rialzo o al ribasso ma non azzerabile</li>
                <li>prodotti NOSTAR in gamma diversa da P: modificabile liberamente</li>
              </ul>
            </>}>
              <Icon type="question-circle" theme="filled" style={{ color: 'rgba(0, 0, 0, .45)', marginLeft: '10px' }} />
            </Tooltip>
          </>
        ),
        cleanTitle: 'PREV VENDUTO NEGOZIO',
        dataIndex: 'prevVtot',
        contentType: 'decimal',
        precision: 1,
        customVisibility: [ROLE_CS, ROLE_GUEST].includes(user.role) && opecom && opecom.idStore !== null,
        editable: (value, record) =>  {
          const indexQtaImpegnata = (record.qtaImpegnata || []).findIndex(o => parseInt(o.storeId) === parseInt(opecom.idStore));
          let qtaImpegnata = 0;

          if (indexQtaImpegnata > -1) {
            qtaImpegnata = parseFloat(record.qtaImpegnata[indexQtaImpegnata].qta);
          }

          return user.role === ROLE_CS && opecom.statoOpecom === 'CC' && record.odrCentralizzati !== true && (record.meccom === '4' || (record.meccom !== '4' && !record.refImport)) && qtaImpegnata <= 0;
        },
        width: 130,
        minValue: record => {
          if (record.tipoProdotto === 'STAR' && !record.refNomeReparto.startsWith('01')) {
            if (
              record.meccom === '3' &&
              record.volantino === true &&
              record.refImport !== true &&
              (!record.prevVtotRelex || record.prevVtotRelex <= 0)
            ) {
              return 1;
            }

            return record.prevVtotRelex
          }

          if (
            record.meccom === '3' &&
            record.refImport !== true &&
            record.volantino === true
          ) {
            return 1;
          }

          return 0          
        }
      },
      {
        title: (
          <>
            <span className="special-table-head-title">
              <i>PREV VENDUTO</i> <small>WEB (QTA)</small>
            </span>
            <Tooltip title={<>
              Valgono le seguenti regole:
              <ul>
                <li>prodotti STAR: modificabile solo al rialzo rispetto al valore di Relex</li>
                <li>prodotti NOSTAR in gamma P: modificabile al rialzo o al ribasso ma non azzerabile</li>
                <li>prodotti NOSTAR in gamma diversa da P: modificabile liberamente</li>
              </ul>
            </>}>
              <Icon type="question-circle" theme="filled" style={{ color: 'rgba(0, 0, 0, .45)', marginLeft: '10px' }} />
            </Tooltip>
          </>
        ),
        cleanTitle: 'PREV VENDUTO WEB',
        dataIndex: 'prevVtot',
        contentType: 'decimal',
        precision: 1,
        customVisibility: [ROLE_CS_WEB].includes(user.role) && opecom && opecom.idStore === 412,
        editable: (value, record) =>  {
          const indexQtaImpegnata = (record.qtaImpegnata || []).findIndex(o => parseInt(o.storeId) === parseInt(opecom.idStore));
          let qtaImpegnata = 0;

          if (indexQtaImpegnata > -1) {
            qtaImpegnata = parseFloat(record.qtaImpegnata[indexQtaImpegnata].qta);
          }

          return user.role === ROLE_CS_WEB && opecom.statoOpecom === 'CC' && record.odrCentralizzati !== true && (record.meccom === '4' || (record.meccom !== '4' && !record.refImport)) && qtaImpegnata <= 0;
        },
        width: 130,
        minValue: record => {
          return record.prevVtotRelex
        }
      },
      {
        title: 'QTA IMPEGNATA NEGOZIO',
        dataIndex: 'qtaImpegnata',
        align: 'center',
        width: 125,
        onHeaderCell: column => ({style: {minWidth: '100px'}}),
        onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
        renderer: 'modalRenderer',
        cellRendererParams: {
          content: <OpecomQtaImpegnataModalContent />,
          title: 'Modifica QTA impegnata negozi',
          onOk: () => {},
          onCancel: () => {},
          canEditQtaImpegnata: record => opecom.idStore === null && user.role === ROLE_CP && ['CR', 'R1'].includes(opecom.statoOpecom) && !record.odrCentralizzati,
          storeId: opecom.idStore
        }
      },
      {
        title: (
          <span className="special-table-head-title">
            <i>PREV VENDUTO</i> TOTALE (K%)
          </span>
        ),
        cleanTitle: 'PREV VENDUTO TOTALE (K%)',
        dataIndex: 'prevVtotPerc',
        contentType: 'decimal',
        precision: 2,
        customVisibility:
          [ROLE_CP, ROLE_GUEST].includes(user.role) && opecom && opecom.idStore === null,
        editable: (value, record) => {
          if (record.lottoRef !== 'NO') {
            return false;
          }

          if (!['CR', 'R1'].includes(opecom.statoOpecom)) {
            return false;
          }

          if (record.odrCentralizzati === true) {
            return false;
          }

          if (opecom.idStore !== null) {
            return false;
          }

          if (user.role === ROLE_CP && record.meccom === '4' && record.tipoProdotto !== 'NOSTAR') {
            return true;
          }

          if (user.role === ROLE_CP && record.meccom !== '4') {
            return true;
          }

          return false;
        }
           /* &&
          (record.tipoProdotto === 'STAR' ||
            (record.tipoProdotto === 'NOSTAR' &&
              (['INI', 'PRE'].includes(record.stato) ||
                record.refGamma === 'P'))) */,
        batchEditable: true,
        disabled: record => record.prevVtot || record.prevVtot === 0.0,
        width: 130,
        warning: (value, record) => {
          return value > 900;
        },
        warningText: "Attenzione: valore superiore a 900%",
      },
      {
        title: (
          <span className="special-table-head-title">
            <i>STOCK PRESENTAZIONE</i> PROMO
          </span>
        ),
        cleanTitle: 'STOCK PRESENTAZIONE PROMO',
        dataIndex: 'stock',
        key: 'stock',
        contentType: 'decimal',
        precision: 1,
        customVisibility:
          [ROLE_AZ_COMM, ROLE_CP, ROLE_GUEST].includes(user.role) && opecom && opecom.idStore === null,
        editable: (value, record) => {
          if (record.lottoRef !== 'NO') {
            return false;
          }

          if (!['CR', 'R1'].includes(opecom.statoOpecom)) {
            return false;
          }

          if (record.odrCentralizzati === true) {
            return false;
          }

          if (user.role === ROLE_AZ_COMM && record.tipoProdotto === 'STAR') {
            return true;
          }

          if (user.role === ROLE_CP && record.meccom === '4' && record.tipoProdotto !== 'NOSTAR') {
            return true;
          }

          if (user.role === ROLE_CP && record.meccom !== '4') {
            return true;
          }

          return false;
         },
        batchEditable: true,
        width: 170,
      },
      {
        title: (
          <span className="special-table-head-title">
            <i>STOCK PRESENTAZIONE</i> RELEX
          </span>
        ),
        cleanTitle: 'STOCK PRESENTAZIONE RELEX',
        dataIndex: 'stockRelex',
        contentType: 'decimal',
        precision: 1,
        customVisibility: [ROLE_CS, ROLE_GUEST].includes(user.role) && opecom && opecom.idStore !== null,
        width: 170,
      },
      {
        title: (
          <span className="special-table-head-title">
            <i>STOCK PRESENTAZIONE</i> NEGOZIO
          </span>
        ),
        cleanTitle: 'STOCK PRESENTAZIONE NEGOZIO',
        dataIndex: 'stock',
        contentType: 'decimal',
        precision: 1,
        customVisibility: [ROLE_CS, ROLE_GUEST].includes(user.role) && opecom && opecom.idStore !== null,
        minValue: record => {
          if (record.refNomeReparto.startsWith('01')) {
            return 0;
          }

          return record.stock_relex || 0;
        },
        editable: (value, record) => {
          const indexQtaImpegnata = (record.qtaImpegnata || []).findIndex(o => parseInt(o.storeId) === parseInt(opecom.idStore));
          let qtaImpegnata = 0;

          if (indexQtaImpegnata > -1) {
            qtaImpegnata = parseFloat(record.qtaImpegnata[indexQtaImpegnata].qta);
          }

          return user.role === ROLE_CS
            && opecom.statoOpecom === 'CC'
            && record.prevVtot > 0 && record.odrCentralizzati !== true && qtaImpegnata <= 0 && record.refGamma !== 'D';
        } ,
        width: 170,
      },
      {
        title: 'ODR CENTRALIZZATI',
        dataIndex: 'odrCentralizzati',
        contentType: 'boolean',
        editable: () => user.role === ROLE_CP && opecom.idStore === null && ['CR', 'R1'].includes(opecom.statoOpecom),
        filtrable: true,
        filters: [
          { value: true, text: 'Si' },
          { value: false, text: 'No' }
        ],
        filterMultiple: false
      },
      {
        title: 'EAN',
        dataIndex: 'ean',
        key: 'refEan',
        searchable: true,
        width: 150,
      },
      {
        title: 'CODICE FORNITORE',
        dataIndex: 'codiceFornitore',
        searchable: true,
        sortable: true,
        width: 250,
      },
      {
        title: 'NOME FORNITORE',
        dataIndex: 'nomeFornitore',
        searchable: true,
        sortable: true,
        width: 250,
      },
      {
        title: () => <span className="special-table-head-title"><i>PREZZO</i> VENDITA</span> ,
        cleanTitle: 'PREZZO VENDITA',
        dataIndex: 'refPv',
        align: 'center',
        contentType: 'money',
        width: 100,
        onHeaderCell: column => ({style: {minWidth: '100px'}}),
        onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
      },
      {
        title: () => <span className="special-table-head-title"><i>PREZZO</i> VENDITA PROMO</span> ,
        cleanTitle: 'PREZZO VENDITA PROMO',
        dataIndex: 'prezzoVenditaOpecom',
        align: 'center',
        contentType: 'money',
        width: 150,
        sortable: true,
        searchable: true,
        onHeaderCell: column => ({style: {minWidth: '100px'}}),
        onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
      },
      {
        title: () => <span className="special-table-head-title"><i>PREV VENDUTO</i> VALORE</span> ,
        cleanTitle: 'PREV VENDUTO VALORE',
        dataIndex: null,
        align: 'center',
        contentType: 'money',
        width: 125,
        onHeaderCell: column => ({style: {minWidth: '100px'}}),
        onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
        render: (value, record) => num(record.prevVtot * record.prezzoVenditaOpecom).format('$0,0.00')
      },
      {
        title: () => <span className="special-table-head-title"><i>VENDUTO ANNO PREC</i> QTA</span>,
        cleanTitle: 'VENDUTO ANNO PREC QTA',
        dataIndex: 'vstore',
        align: 'center',
        contentType: 'decimal',
        customVisibility: opecom && opecom.idStore !== null,
        width: 125,
        onHeaderCell: column => ({style: {minWidth: '100px'}}),
        onCell: (record, rowIndex) => ({style: {minWidth: '100px'}})
      },
      {
        title: 'REF LOTTO',
        dataIndex: 'lottoRef',
        key: 'lottoRef',
        searchable: true,
        sortable: true,
        width: 150,
      },
      {
        title: 'REF SIMILARE',
        dataIndex: 'idRefSimilare',
        key: 'idRefSimilare',
        editable: !opecom || (user.role === ROLE_CP && ['CR', 'R1'].includes(opecom.statoOpecom)),
        align: 'center',
        width: 100,
        searchable: true,
        onHeaderCell: column => ({style: {minWidth: '130px'}}),
        onCell: (record, rowIndex) => ({style: {minWidth: '130px'}}),
        validation: true,
        validationOpts: {
          onBlurAjaxCall: (value, record, column) => new Promise((resolve, reject) => {
            if (_.isNil(value) || _.isEmpty(value)) {
              resolve();
            } else {
              api.references.getById(value).then(response => {
                resolve(response);
              }).catch(error => {
                feedbackNotification('error', 'Referenza inesistente', 'La referenza sostituita inserita non è valida');
                reject();
              });
            }
          }),
          onSuccess: (response, record, handleRowChange) => {}
        }
      },
      {
        title: 'PRIORITA',
        dataIndex: 'priorita',
        contentType: 'integer',
        editable: () => user.role === ROLE_CP && opecom.idStore === null && ['CR', 'R1'].includes(opecom.statoOpecom),
        key: 'priorita',
        searchable: true,
        sortable: true,
        width: 150,
      },
      {
        title: 'NOTE CP',
        dataIndex: 'noteCp',
        editable:
          !opecom ||
          (user.role === ROLE_CP &&
            opecom.idStore === null &&
            opecom.statoOpecom === 'CR'),
        contentType: 'note',
        width: 100,
      },
      {
        title: 'NOTE CS',
        dataIndex: 'noteCs',
        contentType: 'note',
        customVisibility: opecom && opecom.idStore,
        editable: !opecom || user.role === ROLE_CS && opecom.statoOpecom === 'CC',
        width: 100
      },
    ].map(column => {
      column.align = 'center'

      column.onHeaderCell = column => ({
        style: { minWidth: `${column.width}px` },
      })

      column.onCell = (record, rowIndex) => ({
        style: { minWidth: `${column.width}px` },
      })

      return column
    })

    if (opecom) {
      columns = columns.filter(column => {
        // if (
        //   column.dossierStates &&
        //   !column.dossierStates.includes(dossier.statoDossier)
        // ) {
        //   return false
        // }

        if (column.customVisibility !== undefined && !column.customVisibility) {
          return false
        }

        // if (column.visibleTo && !column.visibleTo.includes(user.role)) {
        //   return false
        // }

        return true
      })
    }

    return columns
  }

  /**
   * Handle column visibility change
   * @param column
   */
  handleColumnVisibilityChange = column => {
    const { visibleColumns } = this.state

    if (visibleColumns.indexOf(column.dataIndex) > -1) {
      this.setState({
        visibleColumns: [
          ...visibleColumns.filter(value => {
            if (column.groupWith) {
              return value !== column.dataIndex && value !== column.groupWith
            }

            return value !== column.dataIndex
          }),
        ],
      })
    } else {
      const newVisibleColumns = [...visibleColumns, column.dataIndex]

      if (column.groupWith) {
        newVisibleColumns.push(column.groupWith)
      }

      this.setState({ visibleColumns: newVisibleColumns })
    }
  }

  /**
   * Get visible columns menu
   * @returns {*}
   */
  getVisibleColumnsMenu = () => {
    const { visibleColumns } = this.state
    const { opecom } = this.props

    return (
      <Menu>
        {this.getColumns(opecom)
          .filter(column => column.hideFromVisibility !== true)
          .map(column => (
            <Menu.Item key={column.dataIndex}>
              <Checkbox
                onChange={e => this.handleColumnVisibilityChange(column)}
                checked={visibleColumns.indexOf(column.dataIndex) > -1}>
                {_.isFunction(column.title)
                  ? _.get(column, 'cleanTitle', column.title())
                  : column.title}
              </Checkbox>
            </Menu.Item>
          ))}
      </Menu>
    )
  }

  /**
   * Retrieve data for table
   */
  fetchData = () => new Promise(resolve => {
    const { opecom } = this.props
    const { page, sorter, filters } = this.state

    if (_.isNil(opecom)) {
      return
    }

    const processedFilters = transformFilters(filters, this.getColumns(opecom), sorter)

    const postObj = {
      nPage: page - 1,
      dimPage: 25,
      ...processedFilters,
    }

    this.setState({ tableLoading: true })

    api.opecom.getRefs(opecom.id, postObj).then(result => {
      this.setState({
        tableLoading: false,
        items: _.get(result, 'data.elements', []).map(item => ({
          key: item.idReferenza,
          ...item,
          $errors: [],
        })),
        totalItems: _.get(result, 'data.numTotElements', 0),
        selectedKeys: [],
      }, resolve)
    })
  })

  /**
   * Handle page change
   * @param page
   * @param pageSize
   */
  handlePageChange = (page, pageSize) => {
    this.setState({ page: page })
  }

  /**
   * Handle table change
   * @param filters
   * @param sorter
   */
  handleTableChange = (filters, sorter) => {
    this.setState({
      nPage: 1,
      filters,
      sorter
    })
  }

  /**
   * Set selected/deselected keys
   * @param record
   * @param selected
   */
  handleSelect = (record, selected) => {
    const {selectedAll} = this.state

    if (selectedAll) {
        if (selected)  {
            this.setState(state => ({deselectedKeys: state.deselectedKeys.filter(key => key !== record.key)}))
        } else {
            this.setState(state => ({deselectedKeys: [...state.deselectedKeys, record.key]}))
        }
    } else {
        if (selected)  {
            this.setState(state => ({selectedKeys: [...state.selectedKeys, record.key]}))
        } else {
            this.setState(state => ({selectedKeys: state.selectedKeys.filter(key => key !== record.key)}))
        }
    }
  }

  saveRecords = () => {
    const {recordChanges} = this.state
    const {opecom} = this.props

    this.agTable.current.state.gridApi.showLoadingOverlay();

    api.opecom.saveProducts(opecom.id, Object.keys(recordChanges).map(key => recordChanges[key].data)).then(response => {
      const updates = [];
      const updatedRecords = response.data;
      const changedRowIds = [];
      const updatedRecordChanges = {...recordChanges};

      for (const recordChangeKey in recordChanges) {
        if (!recordChanges.hasOwnProperty(recordChangeKey)) {
          continue;
        }

        const recordChange = recordChanges[recordChangeKey];
        const updatedRecord = updatedRecords.find(updatedRecord => updatedRecord.idReferenza === recordChange.data.idReferenza);

        if (updatedRecord) {
          const data = recordChange.data;

          for (const key in updatedRecord) {
            if (!updatedRecord.hasOwnProperty(key) || !data.hasOwnProperty(key)) {
              continue;
            }

            data[key] = updatedRecord[key];
          }

          if (Array.isArray(data.errors) && data.errors.length > 0) {
            changedRowIds.push(recordChangeKey);
          } else {
            delete updatedRecordChanges[recordChangeKey];
          }

          updates.push(data);
        } else {
          delete updatedRecordChanges[recordChangeKey];
        }
      }
      this.agTable.current.state.gridApi.applyTransaction({
        update: updates
      });
      this.agTable.current.state.gridApi.redrawRows(Object.keys(recordChanges).map(key => recordChanges[key]));
      this.agTable.current.cleanChangedRowIds(changedRowIds);
      this.setState({
        recordChanges: updatedRecordChanges
      });

      // Notifications
      const productsWithErrors = response.data.filter(record => record.errors.length > 0).length;
      let incompleteProducts = 0
      this.agTable.current.state.gridApi.forEachNode((node, index) => {
        if (this.getIncompleteProductWarnings(node.data).length > 0) {
          incompleteProducts++
        }
      })

      if (productsWithErrors === 0 && incompleteProducts === 0) {
        handleServerSuccess();
      }

      if (productsWithErrors > 0) {
        let message = `Ci sono ${productsWithErrors} referenze non salvate a causa di errori`;
        if (productsWithErrors === 1) {
          message = `C'è una referenza non salvata a causa di errori`;
        }

        feedbackNotification('warning', `Operazione parziale`, message);
      }

      if (incompleteProducts > 0) {
        let message = `Ci sono ${incompleteProducts} referenze ancora da lavorare`;
        if (incompleteProducts === 1) {
          message = `C'è una referenza ancora da lavorare`;
        }

        feedbackNotification(null, `Referenze incomplete`, message, <Icon type="warning" style={{ color: colors.yellow }}
        />);
      }
    }, handleServerError);
  }

  /*
  saveRecords = () => {
    const { changes } = this.state
    const { opecom } = this.props

    api.opecom.saveRefs(opecom.id, changes).then(response => {
      const numRefIncomplete = _.get(response, 'data.numRefIncomplete', 0)
      const refNotUpdated = _.get(response, 'data.refNotUpdated', [])
      if (numRefIncomplete > 0) {
        feedbackNotification(
          'warning',
          `Attenzione! ${
            numRefIncomplete === 1
              ? `C'è 1 referenza`
              : `Ci sono ${numRefIncomplete} referenze`
          } ancora da lavorare`
        )
      }

      if (refNotUpdated.length > 0) {
        feedbackNotification(
          'warning',
          `Attenzione! ${
            refNotUpdated.length === 1
              ? `C'è 1 referenza che non è stata aggiornata`
              : `Ci sono ${refNotUpdated.length} referenze che non sono state aggiornate`
          }`
        )
      }

      if (numRefIncomplete === 0 && refNotUpdated.length === 0) {
        handleServerSuccess()
      }

      this.fetchData()
      this.setState({ changes: [], refNotUpdated: refNotUpdated.map(ref => ref.idReferenza) })
    }, handleServerError)
  }
  */

  /**
   * Handle select all
   * @param selected
   * @param selectedRows
   * @param changeRows
   */
  handleSelectAll = (selected, selectedRows, changeRows) => {
    this.setState({selectedAll: selected, selectedKeys: [], deselectedKeys: []})
  };

  /**
   * Edit multiple rows
   */
  editMultipleRows = () => {
    const {selectedKeys, deselectedKeys, selectedAll, filters} = this.state;
    let references = selectedKeys;

    if (selectedAll) {
      references = deselectedKeys;
    }

    _.invoke(this.props, 'onBatchEdit', selectedAll === true ? 'unchecked' : 'checked', filters, references);
  }

  buildRowChange = (changes, value, record, dataIndex) => {
    const changesClone = _.cloneDeep(changes)
    const changesIndex = changes.findIndex(
      change => change.idReferenza === record.key
    )
    if (changesIndex === -1) {
      // If item is being changed from the original (i.e. no change is recorded), push new change
      const change = { idReferenza: record.key }

      // Send all editable properties to allow nullification
      const editableProps = this.getColumns()
        .filter(column => column.editable)
        .map(column => column.dataIndex)

      for (let prop of editableProps) {
        change[prop] = record[prop]
      }

      change[dataIndex] = value

      changesClone.push(change)
      /*  
            REMOVED!!!
            This function is called twice with the same value on the last digit: once when pressed last digit
            , another when field lose focus
        } else if (value === item[dataIndex]) {
            // otherwise, if property is being reverted to original value, delete it
            const changesToThisItem = changesClone[changesIndex];
            delete changesToThisItem[dataIndex];
            // (if "idReferenza" is the only property left, remove object entirely)
            if (Object.keys(changesToThisItem).length === 1) {
                changesClone.splice(changesIndex, 1)
            }*/
    } else {
      // otherwise (item is already changed from the original and is not being reverted), add/modify property change
      const changesToThisItem = changesClone[changesIndex]
      changesToThisItem[dataIndex] = value
    }

    return changesClone
  }

  handleRowChange = (value, record, dataIndex) => {
    const { changes } = this.state

    let changesClone = this.buildRowChange(
      changes,
      value,
      record,
      dataIndex
    )

    if (
      dataIndex === 'tipoProdotto' &&
      value === 'NOSTAR' &&
      !['INI', 'PRE'].includes(record.stato) &&
      record.refGamma !== 'P'
    ) {
      changesClone = this.buildRowChange(
        changesClone,
        null,
        record,
        'prevVtot'
      )

      changesClone = this.buildRowChange(
        changesClone,
        null,
        record,
        'prevVtotPerc'
      )
    }

    if (this.props.user.role === ROLE_CS && dataIndex === 'prevVtot' && value === 0) {
      changesClone = this.buildRowChange(changesClone, 0, record, 'stock')
    }

    this.setState({ changes: changesClone })
  }

  mergeItemsWithChanges = () => {
    const { items, changes } = this.state
    const result = _.cloneDeep(items)

    for (let item of result) {
      const index = changes.findIndex(change => change.idReferenza === item.key)
      if (index !== -1) {
        Object.assign(item, changes[index])
      }
    }

    return result
  }

  handleOnError = errors => {
    this.setState({ canSave: errors.length === 0 })
  }

  getSelectedKeys = () => {
    const { selectedAll, items, selectedKeys, deselectedKeys } = this.state

    if (selectedAll) {
        return items.filter(item => !deselectedKeys.includes(item.key)).map(item => item.key)
    }

    return selectedKeys
  }

  validateProduct = async (change) => {
    const errors = [];
    const { user, opecom, stores } = this.props;
    const { node } = change;
    let inConditionError = false;
    let inEmptyError = false;
    let prevVTot = node.data.prevVtot;
    let prevVTotPerc = node.data.prevVtotPerc;

    if (change?.colDef?.field === 'idRefSimilare' && change.newValue && change.newValue !== change.oldValue) {
      try {
        await api.references.getById(change.newValue);
      } catch (e) {
        errors.push({fields: ['idRefSimilare'], description: 'Referenza similare inesistente'});
      }
    }

    if(user.role === 'CP'){

        if(
          (prevVTot == null || prevVTot == '' || isNaN(Number(prevVTot)))
          && (prevVTotPerc == null || prevVTotPerc == '' || isNaN(Number(prevVTotPerc)))
          && !node.data.odrCentralizzati
        ){
          inEmptyError = true;
        }

        if(inEmptyError){

          // if(node.data.refGamma === 'P'){
          //   inConditionError = true;
          // }
          //
          // if(node.data.tipoProdotto === 'STAR'){
          //   inConditionError = true;
          // }
          //
          // if(node.data.tipoProdotto === 'NOSTAR' && (node.data.meccom == 34 || node.data.meccom == 3 || node.data.meccom == 30)){
          //   inConditionError = true;
          // }

          if (node.data.meccom !== '4') {
            inConditionError = true;
          }

          if(inConditionError){
            errors.push(PREV_VENDUTO_TOT_OBL);
          }

          if (
            (isNaN(Number(node.data.stock)) || Number(node.data.stock) <= 0)
            && node.data.meccom !== '4'
            && node.data.tipoProdotto === 'STAR'
          ) {
            errors.push(STOCK_SEDE_OBL);
          }
      }

      if (
        node.data.volantino === true &&
        (!node.data.prevVtot || node.data.prevVtot <= 0)
      ) {
        errors.push({fields: ['prevVtot'], description: 'Il valore della previsione di venduto deve essere valorizzato per i prodotti in volantino'});
      }
    }


    if(user.role === 'AZ_COMM') {
      if (
        (node.data.stock === '' || node.data.stock === null || typeof node.data.stock !== 'number')
        && node.data.tipoProdotto === 'STAR'
        && node.data.lottoRef === 'NO'
        && node.data.odrCentralizzati === false
      ) {
        errors.push(STOCK_SEDE_OBL);
      }
    }

    if (
      user.role === ROLE_CS
      && opecom &&
      opecom.idStore !== null
      && node.data.stock > node.data.prevVtot
    ) {
      errors.push({fields: ['stock'], description: 'Lo stock di presentazione promo non può superare la previsione di venduto'});
    }

    if ((user.role === ROLE_AZ_COMM || user.role === ROLE_CP) && opecom && opecom.idStore === null && node.data.stock > (node.data.prevVtot / (stores.length || 50))) {
      errors.push({fields: ['stock'], description: 'Lo stock di presentazione promo non può superare la previsione di venduto totale divisa per il numero di negozi'});
    }

    node.setDataValue('errors', errors);
  };


  onAgTableChange = (change) => {
    switch (change.action) {
      case 'cellChange':
        const recordChanges = {
          ...this.state.recordChanges,
          [change.node.id]: change.node
        };

        if (change.colDef && change.colDef.field !== 'errors') {
          this.validateProduct(change);
        }

        const canSave = Object.keys(recordChanges).length > 0 && Object.keys(recordChanges).filter(key => recordChanges[key].data.errors.length > 0).length === 0;

        this.setState({
          recordChanges: recordChanges,
          canSave
        });

        if (change.colDef && change.colDef.field === 'odrCentralizzati' && change.newValue === true) {
          if (change.node.data.prevVtotPerc !== null) {
            change.node.setDataValue('prevVtotPerc', 0);
          }

          if (change.node.data.prevVtot !== null) {
            change.node.setDataValue('prevVtot', 0);
          }

          if (change.node.data.stock !== null) {
            change.node.setDataValue('stock', 0);
          }
        }

        break;

      case 'selectionChange':
        this.setState({
          selectedNodes: [...change.nodes]
        });

        break;

      case 'gridLoaded':
        const visibleColumns = this.getColumns(this.props.opecom)
          .filter(column => column.hidden !== true)
          .map(column => column.dataIndex)
        this.setState({ visibleColumns: visibleColumns })
        this.getIncompleteReferences()
        this.getAllProducts();
        break;

      case 'rowDataChanged':
        console.log('yes');
        this.agTable.current.state.gridApi.forEachNode(node => this.validateProduct({ node }));
        break;

      default:
    }
  };

  getAllProducts = () => {
    this.agTable.current.state.gridApi.showLoadingOverlay();

    const {opecom} = this.props;

    if (_.isNil(opecom)) {
      return;
    }

    api.opecom.getAllRefs(opecom.id).then((response) => {
      this.setState({
        backupData: [...response.data]
      });
      this.agTable.current.loadData(response.data);
      this.agTable.current.state.gridApi.hideOverlay();
    }).catch();

  /*  api.opecom.getAllRefs(opecom.id).then((response) => {
      this.agTable.current.loadData(response.data);
      this.agTable.current.state.gridApi.hideOverlay();
    }).catch(); */
  }

  getIncompleteProductWarnings = (record) => {
    const warnings = [];
    const { user } = this.props;

    if (user.role === ROLE_CP) {

      const prevVtot = Number(record.prevVtot);
      const prevVtotForecast = Number(record.prevVtotForecast);
      const percentageLimit = record.tipoProdotto === 'STAR' ? 1 : 0.5;
      const isForecasted = record.forecasted === true;
      const maximumPrevVtot = prevVtotForecast * (1 + percentageLimit);

      if (isForecasted && isNaN(prevVtotForecast) && !isNaN(prevVtot)) {
        warnings.push({ fields: ['prevVtot'], description: `Il valore del forecast non è ancora stato aggiornato.`})
      } else if (isForecasted && !isNaN(prevVtot) && !isNaN(prevVtotForecast) && prevVtotForecast > 0 && prevVtot > maximumPrevVtot) {
        warnings.push({ fields: ['prevVtot'], description: `Il valore della previsione di venduto è maggiore al valore della previsione di venduto stimato.`});
      }
    }

    return warnings;
  }

  exportDashboard = () => {
    const { opecom } = this.props;
    api.opecom.exportDashboard(opecom.id)
      .then(response => feedbackNotification('info', 'Operazione eseguita', 'Attenzione! Il file del cruscotto sarà disponibile tra poco e verrà inviato via email al tuo indirizzo.'))
      .catch(handleServerError);
};

  /**
   * Component rendering
   * @returns {*}
   */
  render() {
    const {
      visibleColumns,
      totalItems,
      selectedAll,
      selectedKeys,
      columnsMenuVisible,
      tableLoading,
      changes,
      page,
      refNotUpdated,
      canSave,
      filters,
      sorter,
      fullscreen
    } = this.state
    const { opecom,user } = this.props

    let extraStyles = {};

    if (fullscreen) {
      extraStyles = {
        width: '100%',
        height: '100%',
        position: 'fixed',
        backgroundColor: '#ffffff',
        top: 0,
        left: 0,
        padding: '25px 25px 0 25px',
        zIndex: 999
      };
    }

    if (!opecom || !user) {
      return <Loader />;
    }

    return (
      <Wrapper style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', ...extraStyles }}>
         {this.showIncompleteReferencesModal()}
         {this.showOpecomDeadLineModal()}
        <ActionBar>
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 12, offset: 0 }}>
              {AppAccess.isAuthorized('edit_opecom', {
                store: opecom.idStore,
                stato: opecom.statoOpecom,
              }) && (
                <Button
                  icon="save"
                  disabled={Object.keys(this.state.recordChanges).length === 0 || !canSave}
                  onClick={this.saveRecords}>
                  Salva
                </Button>
              )}
            </Col>
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 12, offset: 0 }}>
             <Button icon="download" onClick={this.exportPromo}>Scarica</Button>
             {((user.role === ROLE_AZ_COMM && opecom.idStore === null)&& <Button icon="dashboard" onClick={this.exportDashboard}>Cruscotto</Button>)},
            </Col>
          </Row>
        </ActionBar>
        <Row style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', }}>
          <Col style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <AgTable
              canMakeSelection={AppAccess.isAuthorized('edit_opecom', { stato: opecom.statoOpecom, store: opecom.idStore })}
              ref={this.agTable}
              columns={this.getColumns(opecom)}
              emitChange={(change) => this.onAgTableChange(change)}
              getIncompleteProductWarnings={this.getIncompleteProductWarnings}
            />
          </Col>
        </Row>
        {/*<Row>*/}
        {/*  <Col>*/}
        {/*    <LMTable*/}
        {/*      columns={this.getColumns(opecom)}*/}
        {/*      data={this.mergeItemsWithChanges()}*/}
        {/*      totalItems={totalItems}*/}
        {/*      selection={AppAccess.isAuthorized('edit_opecom', {*/}
        {/*        store: opecom.idStore,*/}
        {/*        stato: opecom.statoOpecom,*/}
        {/*      })}*/}
        {/*      loading={tableLoading}*/}
        {/*      visibleColumns={visibleColumns}*/}
        {/*      onPageChange={this.handlePageChange}*/}
        {/*      onTableChange={this.handleTableChange}*/}
        {/*      onSelect={this.handleSelect}*/}
        {/*      onSelectAll={this.handleSelectAll}*/}
        {/*      selectedKeys={this.getSelectedKeys()}*/}
        {/*      onRowChange={this.handleRowChange}*/}
        {/*      onError={this.handleOnError}*/}
        {/*      currentPage={page}*/}
        {/*      changes={changes}*/}
        {/*      filters={filters}*/}
        {/*      sorter={sorter}*/}
        {/*      tableOptions={{*/}
        {/*        onRow: (record, rowIndex) => {*/}
        {/*          const props = {}*/}

        {/*          if (refNotUpdated.indexOf(record.idReferenza) > -1) {*/}
        {/*            props.className = 'ant-table-row-ref-not-updated'*/}
        {/*          }*/}

        {/*          return props*/}
        {/*        },*/}
        {/*        scroll: { x: true, y: 'calc(100vh - 400px)' },*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </Wrapper>
    )
  }

  exportPromo = () => {
    const { opecom } = this.props;
    const { visibleColumns } = this.state;
    api.opecom.export(opecom.id, visibleColumns)
      .then(response => feedbackNotification('info', 'Operazione eseguita', 'Attenzione! Il file excel con le referenze sarà disponibile tra poco e verrà inviato via email al tuo indirizzo.'))
      .catch(handleServerError);
  };
}

OpecomReferencesTable.propTypes = {
  opecom: PropTypes.object,
}

export default OpecomReferencesTable
